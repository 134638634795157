<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
    >
        <course-head
            :item="item"
            total-capacity
        >
            <template v-if="isMobile" #actions>
                <w-actions-menu
                    icon="MENU"
                    :items="menu"
                />
            </template>
        </course-head>
        <w-toolbar-menu
            v-if="!isMobile"
            :title="menuTitle"
            :items="menu"
            height="52"
            divider
            underlined
            outlined
            class="mt-2"
        />
        <div class="mt-2">
            <router-view></router-view>
        </div>
    </content-wrapper>
</template>

<script>
import { getCourse } from '@apps/school/api/courses'
import CourseHead from '@apps/school/components/Course/CourseHead'
import ContentWrapper from '@/components/ContentWrapper'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'CourseDetails',
    mixins: [
        mobileBreakpointChecker
    ],
    components: {
        ContentWrapper,
        CourseHead
    },
    computed: {
        courseUuid() {
            return this.$route.params.uuid
        },
        breadcrumbs() {
            const breadcrumbs = [
                {
                    text: this.$trans('Courses'),
                    to: { name: 'courses' }
                }
            ]

            if (this.item) {
                breadcrumbs.push({
                    text: this.item ? this.item.title : null
                })
            }

            return breadcrumbs
        },
        menuTitle() {
            return this.item
                ? this.item.title
                : null
        },
        menu() {
            const menu = [
                {
                    text: this.$trans('Documents'),
                    icon: 'DOCUMENTS',
                    to: {
                        name: 'courses.docs',
                        params: { uuid: this.courseUuid }
                    }
                },
                {
                    text: this.$trans('Teachers'),
                    icon: 'TEACHER',
                    to: {
                        name: 'courses.teachers',
                        params: { uuid: this.courseUuid }
                    }
                },
                // {
                //     text: this.$trans('Tests'),
                //     icon: 'QUESTIONS',
                //     to: {
                //         name: 'courses.questions',
                //         params: { uuid: this.courseUuid }
                //     }
                // },
            ]

            if(this.$auth.isTeacher()) {
                menu.push({
                    text: this.$trans('Groups'),
                    icon: 'GROUP',
                    to: {
                        name: 'courses.groups',
                        params: { uuid: this.courseUuid }
                    }
                })
            }

            if(this.$auth.isStudent()) {
                menu.push({
                    text: this.$trans('Day book'),
                    icon: 'DAYBOOK',
                    to: {
                        name: 'daybook.form',
                        query: { g: this.$auth.member.group_uuid, c: this.courseUuid }
                    }
                })
            }

            return menu
        }
    },
    data() {
        return {
            item: null,
            lazyLoadTeachers: false
        }
    },
    mounted() {
        this.fetchCourse()
    },
    methods: {
        async fetchCourse() {
            this.$lprogress.begin()

            try {
                const payload = {
                    with: 'department:title,uuid'
                }

                const response = await getCourse(this.courseUuid, payload)
                this.item = response.data.data
                this.lazyLoadTeachers = true
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
